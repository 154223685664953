const CLICK_ID_KEY = 'click_id'

export const setCityAdsClickId = () => {
  if (!import.meta.client) return

  const paramsArray = window.location.search.substring(1).split('&')
  const paramsResult: Record<string, string> = {}

  if (paramsArray.length) {
    for (let i = 0; i < paramsArray.length; i++) {
      const paramsCurrent = paramsArray[i].split('=')

      paramsResult[paramsCurrent[0]] = paramsCurrent[1]
        ? decodeURIComponent(paramsCurrent[1])
        : ''
    }

    if (paramsResult[CLICK_ID_KEY]) {
      localStorage.setItem(CLICK_ID_KEY, paramsResult[CLICK_ID_KEY])
    }
  }
}

export const sendCityAdsClickId = async (orderId: string) => {
  if (!import.meta.client) return

  const clickId = localStorage.getItem(CLICK_ID_KEY)
  if (!clickId) return

  await fetch(`https://cityadstrack.com/tr/js/${orderId}/ct/q1/c/39618?click_id=${clickId}`, {
    mode: 'no-cors',
  })
}