import FetchFactory from '../factory'
import Routes from '../routes.client'
import type {
  PassportData,
  PassportDataSavingData,
  PassportDataVerifyingData,
  PassportDataGettingData,
  PassportDataRecognizingData,
} from '~/types/models'

class CustomerPassportsModule extends FetchFactory {
  private readonly RESOURCE = Routes.CustomerPassports

  async getCustomerPassport(subscriptionOrderId: string) {
    return this.call<PassportDataGettingData>({
      method: 'GET',
      url: `${this.RESOURCE.GetCustomerPassport(subscriptionOrderId)}`,
    })
  }

  async saveCustomerPassport(data: PassportDataSavingData) {
    return this.call({
      method: 'POST',
      url: `${this.RESOURCE.SaveCustomerPassport()}`,
      body: data,
    })
  }

  async verifyCustomerPassport(data: PassportDataVerifyingData) {
    return this.call({
      method: 'POST',
      url: `${this.RESOURCE.VerifyCustomerPassport()}`,
      body: data,
    })
  }

  async recognizeCustomerPassport(data: PassportDataRecognizingData) {
    return this.call<PassportData>({
      method: 'POST',
      url: `${this.RESOURCE.RecognizeCustomerPassport()}`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      body: data,
    })
  }
}

export default CustomerPassportsModule